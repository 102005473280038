import { formatDate, getCurrentTimezone } from 'common/utils/datetime';
import { forEach, isString, replace, toLower, trim } from 'lodash';
import { phoneRegexValidation } from './validations';

export function removePhoneFormat(phoneNumber: string): string {
    const phoneWithNoFormat = replace(phoneNumber, /[\D]+/g, '');
    const phoneLength = phoneWithNoFormat.length;
    if (phoneLength === 0) {
        // graphQL dont take undefined or blank string, have to reset it to null
        phoneNumber = null;
    } else {
        const countryCode = phoneLength === 10 ? '+1' : '+';
        phoneNumber = countryCode + phoneWithNoFormat;
    }

    return phoneNumber;
}

export function formatPhoneNumber(phoneNumber: string): string {
    if (!phoneNumber) {
        return null;
    }

    if (RegExp(phoneRegexValidation).test(phoneNumber)) {
        const cleaned = replace(phoneNumber, /[\D]+/g, '');
        const match = cleaned.match(/^(\d{1,3}|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            const intlCode = match[1] ? `+${match[1]}` : '+1';
            return `${intlCode} (${match[2]}) ${match[3]}-${match[4]}`;
        }
    }

    return phoneNumber;
}

export function nullifyBlank(string: string): string {
    // if string == blank or undefined, return null, else return value
    return (isString(string) && string === '') || !string ? null : string;
}

export function formatUsername(username: string): string {
    return toLower(replace(username, /[^\w@.\-+!]/g, ''));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function doFormats(formatters, value): any {
    if (!value) {
        return null;
    }
    if (!formatters || !formatters.length) {
        return value;
    }

    let formattedValue = value;

    forEach(formatters, (formatter): void => {
        formattedValue = formatter(formattedValue);
    });

    if (!formattedValue || !trim(formattedValue)) {
        return null;
    }

    return formattedValue;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function doFieldFormatting(allFormats): (value) => any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value): any => doFormats(allFormats, value);
}

export function convertAWSDateToFormat(date: string, format: string, includeTimezone?: boolean): string {
    if (includeTimezone) {
        return formatDate(format, date, null, getCurrentTimezone());
    }
    return formatDate(format, date);
}

export function isTimeOnlyString(str: string): boolean {
    const regex = /^([0-1][0-9]|[2][0-3]):([0-5][0-9]:00.000)$/;
    return regex.test(str);
}

export const eatQuotes = (str: string): string => {
    return replace(str, /"/g, '');
};

export const stripNonNumeric = (value: string) => {
    const notDigitsRegex = RegExp(/[^\d]/g);
    return replace(value, notDigitsRegex, '');
};

export const UnderTest = {
    nullifyBlank,
    formatPhoneNumber,
    removePhoneFormat,
    formatUsername,
    doFormats,
    eatQuotes,
    stripNonNumeric,
};
