import { lazy } from 'react';

export let routes = [
    { component: lazy(() => import('module/auth/login/login.page')), permissions: [], path: '/createPassword/login' },
    {
        component: lazy(() => import('module/auth/login/sso/OktaRelogin')),
        Permissions: [],
        path: '/sso/okta/relogin',
    },
    {
        redirects: '/sso/okta/login',
        permissions: [],
        path: '/sso/okta/logout',
    },
    {
        component: lazy(() => import('module/auth/login/sso/OktaLogout')),
        permissions: [],
        path: '/sso/okta/login',
    },
    { component: lazy(() => import('module/auth/login/sso/OktaSSOPage')), permissions: [], path: '/sso/okta' },
    {
        component: lazy(() => import('module/auth/forgotUser/forgotUser.page')),
        permissions: [],
        path: '/forgotUser',
    },
    {
        component: lazy(() => import('module/auth/security_question/security_question')),
        permissions: [],
        path: '/securityQuestions',
    },
    {
        component: lazy(() => import('module/auth/security_question/security_answers/security_answers')),
        permissions: [],
        path: '/securityQuestions/securityAnswers',
    },
    {
        component: lazy(() => import('module/auth/login/createpass/create_password')),
        permissions: [],
        path: '/createPassword',
    },
    {
        component: lazy(() => import('module/auth/security_question/security_answers/security_answers')),
        permissions: [],
        path: '/resetPassword',
    },
    {
        component: lazy(() => import('module/auth/dashboard/dashboard.page')),
        permissions: [],
        path: '/dashboard',
    },
    {
        component: lazy(() => import('module/auth/login/sso/OktaHandshake')),
        permissions: [],
        path: '/sso/okta/access',
    },
];
