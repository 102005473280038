import { removeHtmlTags } from 'common/utils/common.utils';
import { getMinutes, isAfterDate, isBeforeDate } from 'common/utils/datetime';
import { FieldState, FieldValidator, SubmissionErrors } from 'final-form';
import { forEach, get, isError, isObject, isString, isUndefined, keys, map, replace, some, split, trim } from 'lodash';
import { FC, ReactElement } from 'react';
import { AnyObject, Field, FieldProps, FieldRenderProps, Form, FormProps } from 'react-final-form';
import { doFieldFormatting } from './formats';

export interface InputProps<FieldValue, RP extends FieldRenderProps<FieldValue, T, any>, T extends HTMLElement>
    extends FieldProps<FieldValue, RP, T> {
    t?: Function;
    validations?: FieldValidator<FieldValue>[]; // List of validation functions to apply
}

export const emailRegexValidation = "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]{2,}){1,}$";
// Password Regex checks for required characters for password, DOES NOT CHECK FOR NOT ALLOWED CHARACTERS!
export const simpleEmailRegexValidation = '^.+@.+\\..+$';
export const passwordReqRegexValidation = '^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[.@_\\+$%!?]).{8,}$';
export const phoneRegexValidation = '^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})$';
export const hexColorValidation = '^#([\\da-fA-F]{6})$';
export const percentOrPxValidation = '^(([1-9][0-9]?|0|100)%)|(\\d+px)$';
export const noSpecialCharacters = '^[\\d\\w ]*$';
export const urlValidation =
    '^(https?://)?((([a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9])|([a-zA-Z0-9]+))\\.){1,4}(([a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9])|([a-zA-Z0-9]+))(\\/([!#$&-;=?-\\[\\]_a-z~]|%[0-9a-fA-F]{2})*)*$';
/** 
 It should not call a valid phone number invalid.
 It might call an invalid phone number valid, otherwise strictly checking may end-up with regex not allowing valid phone numbers
 */
export const isPhoneGlobalRegex = /^(\+|00)?[0-9 \-()./]{2,32}$/;
export const isNumberRegex = '^[0-9]*$';
export const isFloatRegex = /^(\d*|\d+\.\d+)$/;
export const isOneDecimalNumber = /^\d+\.\d$/;

export const isValidDate =
    (errorKey?: string) =>
    (value: string, allValue?, meta?): string =>
        isValidDate(value) ? undefined : meta ? meta.t(errorKey || 'error.invalid_date') : errorKey || 'invalidDate';

/**
 * Return validator bound to the supplied error key, which will determine if the supplied value is valid
 */
export const required = (errorKey: string = 'error.required'): ((value, allValue?, meta?) => any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any, allValue, meta): string => {
        return value && trim(value) ? undefined : meta ? meta.t(errorKey) : errorKey;
    };
};

export const unique = (isUnique: boolean): ((value, allValue?, meta?) => any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any, allValue, meta): string => {
        return !isUnique && value && meta.t('error.unique');
    };
};

export const minLength = (minLength: number, errorKey?: string): ((value, allValue?, meta?) => any) => {
    // value could be optional, dont throw error if blank.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any, allValue, meta): string =>
        !value || value.length >= minLength
            ? undefined
            : meta
              ? meta.t(errorKey || 'error.too_short', { minLength: minLength })
              : errorKey || 'tooShort';
};

export const maxLength = (
    maxLength: number,
    errorKey?: string,
    maxLengthForTranslation?: string
): ((value, allValue?, meta?) => any) => {
    // value could be optional, dont throw error if blank.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any, allValue, meta): string =>
        !value || value.length <= maxLength
            ? undefined
            : meta
              ? meta.t(errorKey || 'error.max_length', { maxlength: maxLengthForTranslation || maxLength })
              : errorKey || 'tooLong';
};

export const pattern = (pattern: string | RegExp, errorKey?: string): ((value, allValue?, meta?) => any) => {
    // value could be optional, dont throw error if blank.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any, allValue, meta): string => {
        const _value = value?.toString();
        return !_value || _value.match(isString(pattern) ? new RegExp(pattern) : pattern)
            ? undefined
            : meta
              ? meta.t(errorKey || 'error.patternMismatch')
              : errorKey || 'patternMismatch';
    };
};

export const intOnly = (errorKey?: string): ((value, allValue?, meta?) => any) => {
    return (value: any, allValue, meta): string =>
        pattern(isNumberRegex, errorKey || (meta ? 'error.must_be_numbers' : 'intOnly'))(value, allValue, meta);
};

export const numberOnly = (errorKey?: string): ((value, allValue?, meta?) => any) => {
    return (value: any, allValue, meta): string =>
        pattern(isFloatRegex, errorKey || (meta ? 'error.must_be_decimal_numbers' : 'numberOnly'))(
            value,
            allValue,
            meta
        );
};

export const validTextUnitFieldDimension = (errorKey?: string): ((value, allValue?, meta?) => any) => {
    return (value: unknown, allValue, meta): string => {
        const namePrefix = replace(meta.name, /^(.*?)(Value|Unit)$/, '$1');
        const compoundValue = `${get(allValue, meta.name)}${get(allValue, `${namePrefix}Unit`)}`;
        return !compoundValue || compoundValue.match(new RegExp(percentOrPxValidation))
            ? undefined
            : meta
              ? meta.t(errorKey || 'error.wrong_dimension')
              : errorKey;
    };
};

export const minDateTime = (
    minDate: Date,
    errorKey: string = 'error.min_date_error'
): ((value, allValue?, meta?) => any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any, allValue, meta): string => {
        return value && minDate && isBeforeDate(value, minDate, 'minutes')
            ? meta
                ? meta.t(errorKey)
                : errorKey
            : undefined;
    };
};
export const maxDateTime = (
    maxDate: Date,
    errorKey: string = 'error.max_date_error'
): ((value, allValue?, meta?) => any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any, allValue, meta): string => {
        return value && maxDate && isAfterDate(value, maxDate, 'minutes')
            ? meta
                ? meta.t(errorKey)
                : errorKey
            : undefined;
    };
};
export const intervalValidation = (
    interval: number,
    errorKey: string = 'error.interval_error'
): ((value, allValue?, meta?) => any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any, allValue, meta): string => {
        return value && interval && getMinutes(value) % interval !== 0
            ? meta
                ? meta.t(errorKey, { interval: interval })
                : errorKey
            : undefined;
    };
};
export const oneDecimalNumber = (errorKey?: string): ((value, allValue?, meta?) => any) => {
    return (value: any, allValue, meta): string =>
        pattern(isOneDecimalNumber, errorKey || (meta ? 'error.must_be_one_decimal_numbers' : 'oneDecimalNumber'))(
            value,
            allValue,
            meta
        );
};

export const floatNumber = (errorKey?: string): ((value, allValue?, meta?) => any) => {
    return (value: any, allValue, meta): string =>
        pattern(isFloatRegex, errorKey || (meta ? 'error.must_be_float_number' : 'floatNumber'))(value, allValue, meta);
};

export const intMax = (
    upperBound: number,
    errorKey?: string,
    upperBoundForTranslation?: string
): ((value, allValue?, meta?) => any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any, allValue, meta): string =>
        value?.toString()?.match(isNumberRegex) && parseInt(value) > upperBound
            ? meta
                ? meta.t(errorKey || 'error.must_be_less_than', { upperBound: upperBoundForTranslation || upperBound })
                : errorKey || 'tooLarge'
            : undefined;
};

export const intMaxPercent = (
    upperBound: number,
    errorKey?: string,
    upperBoundForTranslation?: string
): ((value, allValue?, meta?) => any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any, allValue, meta): string =>
        split(value, '%')[0]?.toString()?.match(isNumberRegex) && parseInt(value) > upperBound
            ? meta
                ? meta.t(errorKey || 'error.must_be_less_than', { upperBound: upperBoundForTranslation || upperBound })
                : errorKey || 'tooLarge'
            : undefined;
};

export const intMin = (lowerBound: number, errorKey?: string): ((value, allValue?, meta?) => any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any, allValue, meta): string =>
        value?.toString()?.match(isNumberRegex) && parseInt(value) < lowerBound
            ? meta
                ? meta.t(errorKey || 'error.must_be_greater_than', { lowerBound: lowerBound })
                : errorKey || 'tooSmall'
            : undefined;
};

export const intBetween = (lowerBound: number, upperBound: number, errorKey?: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any, allValue?, meta?): string => {
        let _errorKey = undefined;
        if (!value?.toString()?.match(isNumberRegex)) {
            _errorKey = undefined;
        } else if (parseInt(value) < lowerBound) {
            _errorKey = errorKey || 'error.must_be_greater_than';
        } else if (parseInt(value) > upperBound) {
            _errorKey = errorKey || 'error.must_be_less_than';
        } else {
            return undefined;
        }

        if (_errorKey === undefined) {
            return _errorKey;
        } else {
            return meta
                ? meta.t(_errorKey, { lowerBound: lowerBound, upperBound: upperBound })
                : errorKey || 'outOfBounds';
        }
    };
};
// did not use meta as this validation is only for ValidIndicatorLabel component
export const invertPattern = (pattern: string, errorKey?: string): ((value) => any) => {
    // value could be optional, dont throw error if blank.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any): any => {
        return !value || !value.match(new RegExp(pattern)) ? undefined : errorKey || 'containInvertedPattern';
    };
};

export const noWhiteSpace = (errorKey?: string): ((value, allValue?, meta?) => any) => {
    return (value: any, allValue, meta): string => {
        return !value || !!value.match(new RegExp('^[^\\s]+$'))
            ? undefined
            : meta
              ? meta.t(errorKey || 'error.contain_spaces')
              : errorKey || 'containWhiteSpace';
    };
};
// did not use meta as this validation is only for ValidIndicatorLabel component
export const noSpaceAround = (errorKey?: string): ((value) => any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any): string => {
        return !value || value === trim(value) ? undefined : errorKey || 'trimWhiteSpace';
    };
};

export const validUsername = (errorKey?: string): ((value, allValue?, meta?) => any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any, allValue, meta): string => {
        // regex checks for anything that is not latin characters, digits, special characters @ . - _ + !
        // returns error if any not allowed character is matched
        return !value || !value.match(new RegExp('[^a-zA-Z0-9@.\\-_+!]'))
            ? undefined
            : meta
              ? meta.t(errorKey || 'error.invalid_username')
              : errorKey || 'invalidUsername';
    };
};

export const validPassword = (errorKey?: string): ((value, allValue?, meta?) => any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any, allValue, meta): string => {
        // regex checks for anything that is not latin characters, digits, special characters @ . - _ + !
        // returns error if any not allowed character is matched, or did not contain the required characters
        return !value ||
            (!value.match(new RegExp('[^a-zA-Z0-9@.\\-_+$%!?\\s]')) &&
                !!value.match(new RegExp('^[^\\s].+[^\\s]$')) &&
                value.match(new RegExp(passwordReqRegexValidation)))
            ? undefined
            : meta
              ? meta.t(errorKey || 'error.invalid_password')
              : errorKey || 'invalidPassword';
    };
};

export const validRegex = (errorKey?: string): ((value, allValue?, meta?) => any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any, allValue, meta): string => {
        try {
            new RegExp(value);
            return undefined;
        } catch {
            return meta ? meta.t(errorKey || 'error.not_regex') : errorKey || 'invalidRegex';
        }
    };
};

export const lessThanEqualToField = (
    fieldName: string,
    fieldTranslation: string,
    errorKey?: string
): ((value, allValue?, meta?) => any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any, allValue, meta): string => {
        const referenceValue = get(allValue, fieldName);
        const areNumbers = value?.toString()?.match(isNumberRegex) && referenceValue?.toString()?.match(isNumberRegex);
        return areNumbers && parseInt(value) > parseInt(referenceValue)
            ? meta
                ? meta.t(errorKey || 'error.must_be_less_than', { upperBound: fieldTranslation })
                : errorKey || 'tooSmall'
            : undefined;
    };
};

export const rangeBetweenFields = (
    range: number,
    fieldName: string,
    errorKey?: string
): ((value, allValue?, meta?) => any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any, allValue, meta): string => {
        const referenceValue = get(allValue, fieldName);
        const areNumbers = value?.toString()?.match(isNumberRegex) && referenceValue?.toString()?.match(isNumberRegex);
        return areNumbers && Math.abs(parseInt(value) - parseInt(referenceValue)) > range
            ? meta
                ? meta.t(errorKey || 'error.must_be_in_range', { range })
                : errorKey || 'outOfRange'
            : undefined;
    };
};

export const someFields = (
    fieldNames: string[],
    errorKey: string,
    predicate?: (value: unknown) => boolean
): ((value, allValue?, meta?) => any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any, allValue, meta): string => {
        const values = map(fieldNames, (fieldName) => get(allValue, fieldName));
        return !some(values, predicate) ? (meta ? meta.t(errorKey) : errorKey) : undefined;
    };
};

// Form level validations
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mustMatch = (
    field1: string,
    field2: string,
    errorField: string,
    errorKey?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ((value, allValue?, meta?) => any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    return (value: any, allValues: any, meta: any): object => {
        const errors = {};
        if (allValues && allValues[field1] !== allValues[field2]) {
            errors[errorField] = meta ? meta.t(errorKey || 'error.patternMismatch') : errorKey || 'patternMismatch';
        }
        return errors;
    };
};

export const valueNotInSpecifiedData = (
    dataToCompare: unknown[],
    key: string,
    errorKey: string = 'error.required'
): ((value, allValue?, meta?) => any) => {
    return (value: any, allValue, meta): string => {
        return value && trim(value)
            ? some(dataToCompare, { [key]: value })
                ? meta
                    ? meta.t(errorKey)
                    : errorKey
                : undefined
            : undefined;
    };
};

/*
 * The final-form code seems to imply that final-form can take a list of validations, but the react wrapper
 * around it doesn't appear to allow access to that part of their api so bundling all validations under a single
 * validator function
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const doValidations = (validators: FieldValidator<any>[], allValues, value?, meta?, t?): any => {
    if (!validators || !validators.length) {
        return undefined;
    }

    // Values received from RTE control contain enclosing <p></p> which messes up validation logic
    if (isString(value)) {
        value = removeHtmlTags(value);
    }

    let result;
    if (meta) {
        meta = { ...meta, t };
    }
    forEach(validators, (validator): boolean => {
        result = validator(value, allValues, meta);
        if (result) {
            return false;
        }
    });

    return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const doFieldValidations = (validators: FieldValidator<any>[], t?): ((value, allValue, meta) => any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any, allValues: object, meta: FieldState<any>): any =>
        doValidations(validators, allValues, value, meta, t);
};

const doFormValidations = (validators: any[], t): ((any) => any) => {
    return (values: AnyObject): any => doValidations(validators, values, {}, {}, t);
};

const ValidationField: FC<InputProps<any, any, any>> = (props): ReactElement => {
    const { validations = [], t, formats = [], trimWhiteSpaces, ...rest } = props;

    if (trimWhiteSpaces) {
        formats.push(trim);
    }

    return <Field validate={doFieldValidations(validations, t)} format={doFieldFormatting(formats)} {...rest} />;
};

const ValidationForm = <FormValues extends any>(
    props: FormProps<FormValues> & { validations?: FieldValidator<any>[] } & { t?: Function }
): ReactElement => {
    const { validations = [], t, ...rest } = props;

    return <Form<FormValues> validate={doFormValidations(validations, t)} {...rest} />;
};

export { ValidationField as Field, ValidationForm as Form };

const hasAnyError = (errors: SubmissionErrors): boolean => {
    return some(keys(errors), (key) => {
        const value = errors[key];
        if (value && isObject(value) && !isError(value)) {
            return hasAnyError(value);
        }

        return !isUndefined(value);
    });
};

// workaround of issue https://github.com/final-form/react-final-form/issues/403
export const isFormSubmissionInvalid = (dirtySinceLastSubmit: boolean, submitErrors: SubmissionErrors) => {
    return !dirtySinceLastSubmit && submitErrors && hasAnyError(submitErrors);
};

export const UnderTest = {
    emailRegexValidation,
    passwordReqRegexValidation,
    phoneRegexValidation,
    required,
    minLength,
    maxLength,
    pattern,
    invertPattern,
    noWhiteSpace,
    noSpaceAround,
    validUsername,
    validPassword,
    mustMatch,
    doFormValidations,
    doFieldValidations,
    doValidations,
    urlValidation,
    numberOnly,
    intMax,
    intMaxPercent,
    intMin,
    intOnly,
    minDateTime,
    maxDateTime,
    intervalValidation,
    oneDecimalNumber,
    floatNumber,
    valueNotInSpecifiedData,
    rangeBetweenFields,
};
