import { BaseRoles, RoleVariantEnum } from 'common/graphql/types';
import { preloadLazyComponent } from 'common/utils/loading';
import { activityPath } from 'module/patient/activity/utils/constants';
import { lazy } from 'react';

const PatientChartPage = lazy(() => import('./patientchart/site_activities/onsite/patient_chart.page'));
const PatientChartIndexPage = preloadLazyComponent(() => import('./patientchart/patient_chart_index.page'));
const ActivityTranscriptionPage = lazy(
    () => import('./patientchart/home_activities/transcription/ActivityTranscriptionPage')
);
const InaccessiblePatientPage = preloadLazyComponent(
    () => import('./patientchart/site_activities/onsite/inaccessible_patient_page')
);

export let routes = [
    {
        component: preloadLazyComponent(() => import('module/patient/create_patient.page')),
        permissions: ['Patient_Create'],
        path: '/patient/basicdetails',
        role: [RoleVariantEnum.SiteCoordinator, RoleVariantEnum.SiteInvestigator],
        studySiteRequired: true,
    },
    {
        component: preloadLazyComponent(() => import('module/patient/visit_schedule.page')),
        permissions: ['Patient_Create'],
        path: '/patient/:patientId/:studyId/visitschedule',
        role: [RoleVariantEnum.SiteCoordinator, RoleVariantEnum.SiteInvestigator],
        studySiteRequired: true,
    },
    {
        component: PatientChartIndexPage,
        permissions: ['Patient_Create'],
        path: '/chart/:patientId',
        role: [
            RoleVariantEnum.SiteCoordinator,
            RoleVariantEnum.SiteInvestigator,
            RoleVariantEnum.Monitor,
            RoleVariantEnum.DataManager,
            RoleVariantEnum.Inspector,
            RoleVariantEnum.ProductSupport,
            RoleVariantEnum.DataChangeSupport,
        ],
        studySiteRequired: true,
    },
    {
        component: PatientChartIndexPage,
        permissions: ['Patient_Create'],
        path: '/chart/:patientId/:patientDetails?',
        role: [
            RoleVariantEnum.SiteCoordinator,
            RoleVariantEnum.SiteInvestigator,
            RoleVariantEnum.Monitor,
            RoleVariantEnum.DataManager,
            RoleVariantEnum.Inspector,
            RoleVariantEnum.ProductSupport,
            RoleVariantEnum.DataChangeSupport,
        ],
        studySiteRequired: true,
        canBeLandingPage: true,
    },
    {
        component: PatientChartPage,
        permissions: [
            'Study_Read',
            'Study_Create',
            'Study_Edit',
            'Study_Delete',
            'StudyActivityInstance_Create',
            'StudyActivityInstance_Edit',
        ],
        path: '/chart/:patientId/siteactivities/visit/:visitSk/activities/:activitySk',
        role: [
            BaseRoles.UserId,
            RoleVariantEnum.SiteCoordinator,
            RoleVariantEnum.SiteInvestigator,
            RoleVariantEnum.Monitor,
            RoleVariantEnum.DataManager,
            RoleVariantEnum.Inspector,
            RoleVariantEnum.ProductSupport,
            RoleVariantEnum.DataChangeSupport,
        ],
        studySiteRequired: true,
    },
    {
        component: ActivityTranscriptionPage,
        permissions: ['StudyActivityInstance_Create', 'StudyActivityInstance_Edit'],
        path: '/chart/:patientPk/transcription/:taskInstanceId/:completedBy',
        role: [RoleVariantEnum.SiteCoordinator, RoleVariantEnum.SiteInvestigator],
        studySiteRequired: true,
    },
    {
        component: ActivityTranscriptionPage,
        permissions: ['StudyActivityInstance_Create', 'StudyActivityInstance_Edit'],
        path: '/chart/:patientPk/dcr/:taskInstanceId/:completedBy',
        role: [RoleVariantEnum.DataChangeSupport],
        studySiteRequired: true,
    },
    {
        component: lazy(() => import('module/patient/econsent/econsent.page')),
        permissions: [],
        role: [],
        path: '/chart/:patientId/econsent/:studyPK/:patientSK/:patientName/:ICFSubRowHeader',
    },
    {
        component: InaccessiblePatientPage,
        permissions: [],
        role: [],
        path: '/patientAccessDenied',
    },
    {
        component: lazy(() => import('module/patient/activity/ActivityPage')),
        permissions: [
            'Study_Read',
            'Study_Create',
            'Study_Edit',
            'Study_Delete',
            'StudyActivityInstance_Create',
            'StudyActivityInstance_Edit',
        ],
        path: activityPath,
        role: [
            BaseRoles.UserId,
            RoleVariantEnum.SiteCoordinator,
            RoleVariantEnum.SiteInvestigator,
            RoleVariantEnum.Monitor,
            RoleVariantEnum.DataManager,
            RoleVariantEnum.Inspector,
            RoleVariantEnum.ProductSupport,
            RoleVariantEnum.DataChangeSupport,
        ],
        studySiteRequired: true,
    },
];
